body {
  background-color: #E6E6FA; /* Very light purple background for the entire page */
  margin: 0;
  padding: 0;
  font-family: 'Arial, sans-serif';
}

.plan-list-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f0f8ff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #003366;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  margin-right: 10px;
  color: #ffffff;
}

.brand-name {
  font-size: 1.5rem;
  color: #ffffff;
}

.menu-icon {
  font-size: 1.5rem;
  color: #ffffff;
  cursor: pointer;
  display: none;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-link {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.nav-link:hover {
  background-color: #00509E;
  text-decoration: none;
}

.plan-detail {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f0f8ff; /* Light blue background for the plan detail container */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.plan-date {
  font-size: 2.5rem;
  color: #003366;
  text-align: center;
  margin-bottom: 20px;
}

.section {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
}

.section-title {
  font-size: 1.5rem;
  color: #003366;
  margin-bottom: 10px;
}

.list {
  list-style: none;
  padding: 0;
}

.list-item {
  font-size: 1.2rem;
  color: #333;
  padding: 5px 0;
}

.song-link {
  color: #6A5ACD; /* Dark Slate Blue for links */
  text-decoration: none;
}

.song-link:hover {
  text-decoration: underline;
}

.download-button {
  background-color: #003366;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  display: block;
  width: 100%;
  max-width: 300px;
  margin: 20px auto;
  text-align: center;
}

.download-button:hover {
  background-color: #6A5ACD; /* Dark Slate Blue for hover */
}

.loading {
  text-align: center;
  font-size: 1.5rem;
  color: #003366;
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }
  .nav-links {
    display: none;
    flex-direction: column;
    gap: 10px;
    background-color: #003366;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    padding: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  .nav-links.active {
    display: flex;
  }
}
