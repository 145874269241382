body {
  background-color: #E6E6FA;
  margin: 0;
  padding: 0;
  font-family: 'Arial, sans-serif';
}

.plan-list-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f0f8ff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #003366;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  margin-right: 10px;
}

.brand-name {
  font-size: 1.5rem;
  color: #ffffff;
}

.menu-icon {
  font-size: 1.5rem;
  color: #ffffff;
  cursor: pointer;
  display: none;
  z-index: 1000; /* Ensure it stays on top */
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-link {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.nav-link:hover {
  background-color: #00509E;
  text-decoration: none;
}

.title {
  font-size: 2.5rem;
  color: #003366;
  text-align: center;
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
}

.plan-list {
  list-style: none;
  padding: 0;
}

.plan-item {
  margin-bottom: 10px;
}

.plan-link {
  color: #6A5ACD;
  text-decoration: none;
  font-size: 1.2rem;
}

.plan-link:hover {
  text-decoration: underline;
}

.filters {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.filters label {
  font-size: 1rem;
  color: #003366;
}

.filters input {
  margin-right: 5px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }
  .nav-links {
    display: none;
    flex-direction: column;
    gap: 10px;
    background-color: #003366;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    padding: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 999; /* Ensure it stays above other content */
  }
  .nav-links.active {
    display: flex;
  }
}


.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-button {
  padding: 10px 15px;
  margin: 0 5px;
  border: none;
  background-color: #003366;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.page-button.active {
  background-color: #00509E;
}

.page-button:hover {
  background-color: #00509E;
}